import { Router } from '@angular/router';
import { FirebaseFirestoreModulesService } from './../../../home-module/services/firebase-firestore-modules.service';
import { Module } from './../../../home-module/models/modules';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})

export class NavigationComponent implements OnInit {
  public modules$: Observable<Module[]>;
  public isCollapsed: boolean = true;

  constructor(
    public modulesService: FirebaseFirestoreModulesService,
    private router: Router
  ) { }

  public ngOnInit(): void {
    this.modules$ = this.modulesService.modules;
    this.modulesService.loadAll();
  }

  public accessModule(link: string): void {
    this.router.navigate(['/clients']);
  }
}
