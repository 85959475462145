import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  preserveWhitespaces: true
})

export class ConfirmModalComponent implements OnInit {

  @Input() public title: string;
  @Input() public message: string;
  @Input() public cancelText: string;
  @Input() public confirmText: string;
  @Input() public confirmResult: Subject<boolean>;

  constructor(public activeModal: NgbActiveModal) {}

  public ngOnInit(): void {}

  public onClosePressed(): void {
    this.confirmAndClose(false);
  }

  public onConfirmPressed(): void {
    this.confirmAndClose(true);
  }

  private confirmAndClose(value: boolean): void {
    this.confirmResult.next(value);
    this.activeModal.close();
  }
}
