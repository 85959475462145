import { User } from './../models/user';
import { FirebaseFirestoreAuthService } from './firebase-firestore-auth.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Subject, EMPTY } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FirebaseAuthService {
  public subject: Subject<any> = new Subject<any>();
  public loading: boolean = false;

  constructor(
    public firebaseAuth: AngularFireAuth,
    private firebaseFirestoreAuth: FirebaseFirestoreAuthService
  ) { }

  public async signIn(credentials: Object): Promise<void> {
    this.loading = true;
    await this.firebaseAuth.setPersistence('local')
      .then(_ => this.callSignIn(credentials['email'], credentials['password']));
  }

  public async signUp(credentials: Object): Promise<void> {
    this.loading = true;
    await this.firebaseAuth.setPersistence('session')
      .then(_ => this.callSignUp(credentials));
  }

  private callSignIn(email: string, password: string): void {
    this.firebaseAuth.signInWithEmailAndPassword(email, password)
      .then(
        (success: firebase.default.auth.UserCredential) => !success.user.emailVerified ? this.callSendEmailVerification(success) : this.successSignIn(success),
        (failure: any) => this.failure(failure));
  }

  private callSignUp(credentials: Object): void {
    this.firebaseAuth.createUserWithEmailAndPassword(credentials['email'], credentials['password'])
      .then(
        (success: firebase.default.auth.UserCredential) => this.saveOnFirestore(success, credentials),
        (failure: any) => this.failure(failure));
  }

  private saveOnFirestore(firebaseUser: firebase.default.auth.UserCredential, credentials: Object): void {
    let user: User = { id: firebaseUser.user.uid, name:  credentials['name'], email: firebaseUser.user.email };
    this.firebaseFirestoreAuth.createUser(user)
      .then(
        (success) => this.callSendEmailVerification(firebaseUser),
        (failure) => this.failure(failure));
  }

  private callSendEmailVerification(signInResponse: firebase.default.auth.UserCredential): void {
    signInResponse.user.sendEmailVerification()
      .then(
        (success: any) => this.success(signInResponse),
        (failure: any) => this.failure(failure));
  }

  private successSignIn(res: firebase.default.auth.UserCredential): void {
    this.firebaseFirestoreAuth.loadUser(res.user.uid)
      .then(
        (success) => this.subject.next({ success: this.stopLoadingAndReturnBoolValue(true), content: res.user }),
        (failure) => this.subject.next({ success: this.stopLoadingAndReturnBoolValue(false), content: failure })
      );
  }

  private success(res): void {
    this.subject.next({ success: this.stopLoadingAndReturnBoolValue(true), content: res.user });
  }

  private failure(res): void {
    this.subject.next({ success: this.stopLoadingAndReturnBoolValue(false), content: res.code });
  }

  public async isLogged(): Promise<boolean> {
    this.loading = true;
    return this.firebaseAuth.authState
      .pipe(take(1))
      .toPromise()
      .then(user => user && user.emailVerified ? this.stopLoadingAndReturnBoolValue(true) : this.stopLoadingAndReturnBoolValue(false));
  }

  public async logout(): Promise<boolean> {
    this.loading = true;
    return await this.firebaseAuth.signOut()
    .then(
      (success: any) => this.stopLoadingAndReturnBoolValue(true),
      (failure: any) => this.stopLoadingAndReturnBoolValue(false)
    )
    .catch(
      (failure: any) => this.stopLoadingAndReturnBoolValue(false));
  }

  private stopLoadingAndReturnBoolValue(theReturn: boolean): boolean {
    this.loading = false;
    return theReturn;
  }
}
