import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthenticationErrorMsg } from './../../../authentication-module/utils/error-msg.enum';
import { FirebaseAuthService } from './../../../authentication-module/services/firebase-auth.service';
import { User } from './../../../authentication-module/models/user';
import { UserService } from './../../services/user.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-user-container',
  templateUrl: './user-container.component.html',
  styleUrls: ['./user-container.component.scss']
})

export class UserContainerComponent implements OnInit {
  public user$: Observable<User>;

  constructor(
    public firebaseAuthService: FirebaseAuthService,
    private router: Router,
    private modalService: ModalService,
    public userService: UserService
  ) { }

  public ngOnInit(): void {
    this.listenToUserService();
  }

  public listenToUserService(): void {
    this.user$ = this.userService.userObservable.pipe();
  }

  public onLogoutClick(): void {
    this.firebaseAuthService.logout().then(
      (success: any) => this.router.navigate(['login']),
      (failure: any) => this.modalService.showDangerAlert(AuthenticationErrorMsg.DEFAULT)
    );
  }

}
