import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})

export class AlertModalComponent {

  @Input() public message: string;
  @Input() public type = 'success';

  constructor(public activeModal: NgbActiveModal) { }

  public onClose(): void {
    this.activeModal.dismiss();
  }
}
