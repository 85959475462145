import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from 'src/app/authentication-module/models/user';

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public static _user = new BehaviorSubject<User>(null);

  constructor() { }

  set user(user: User) {
    UserService._user.next(user);
  }

  get userObservable(): Observable<User> {
    return UserService._user.asObservable();
  }
}
