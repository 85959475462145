<ngx-loading [show]="modulesService.loading"></ngx-loading>
<div *ngIf="modules$ | async as modules" class="row row-cols-1 row-cols-sm-2 row-cols-lg-3">
    <div *ngFor="let module of modules" class="col mb-3">
        <div class="card text-center">
            <div class="card-header btn btn-link" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed">{{ module.title }}</div>
            <div class="card-body">
                <div class="accordion">
                    <h5 class="btn btn-lg btn-light btn-block" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed">{{ module.actionLabel }}</h5>
                </div>
                <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
                    <p class="card-text">{{ module.description }}</p>
                    <button *ngFor="let link of module.links" class="btn btn-primary btn-block mb-1" (click)="accessModule(link)">{{ link.title }}</button>
                </div>
            </div>
        </div>
    </div>    
</div>