<div class="container">
    <div class="containe m-4">
        <div class="row justify-content-between align-items-center">
            <div class="col-auto">
                <h5 class="display-4">{{ title }}</h5>
            </div>
            <div class="col">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onClosePressed()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <p class="lead">{{ message }}</p>
        <br>
        <div class="d-flex">
            <div class="ml-auto">
                <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onClosePressed()">
                    {{ cancelText }}
                </button>
                <button type="button" class="btn btn-primary" (click)="onConfirmPressed()">
                    {{ confirmText }}
                </button>
            </div>
            
        </div>
    </div>
</div>
  