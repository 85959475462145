import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './authentication-module/guards/auth.guard';
import { LoginGuard } from './authentication-module/guards/login.guard';
import { WrongRouteComponent } from './shared-module/components/wrong-route/wrong-route.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home-module/home.module').then(m => m.HomeModule),
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ]
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication-module/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [ LoginGuard ],
    canLoad: [ LoginGuard ]
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients-module/clients.module').then(m => m.ClientsModule),
    canActivate: [ AuthGuard ],
    canLoad: [ AuthGuard ]
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: WrongRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
