import { EMPTY } from 'rxjs';
import { FirebaseAuthService } from './authentication-module/services/firebase-auth.service';
import { FirebaseFirestoreAuthService } from './authentication-module/services/firebase-firestore-auth.service';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  public logged: boolean = false;

  constructor(
    private firebaseFirestoreAuthService: FirebaseFirestoreAuthService,
    private firebaseAuthService: FirebaseAuthService,
    private firebaseAuth: AngularFireAuth,
  ){ }

  public ngOnInit(): void {
    this.listenToAuthState();
    this.loadUserOrNot();
  }

  private listenToAuthState(): void {
    this.firebaseAuth.authState.subscribe(
      (user: firebase.default.User) => user ? this.logged = true : this.logged = false
    );
  }

  private loadUserOrNot(): void {
    this.firebaseAuthService.isLogged().then(
      (result: any) => result ? this.loadUser() : EMPTY
    );
  }

  private loadUser(): void {
    this.firebaseAuthService.firebaseAuth.currentUser.then(
      (user: firebase.default.User) => this.firebaseFirestoreAuthService.loadUser(user.uid)
    );
  }
}
