<ngb-alert [type]="type" [dismissible]="false" style="margin-bottom: 0px;">
    <div class="row">
        <div class="col">
            <h4 class="alert-heading">Algo deu errado!</h4>
            {{ message }}
        </div>
        <div class="col-auto">
            <button style="outline: none;" type="button" class="close" aria-label="Close" (click)="onClose()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
</ngb-alert>