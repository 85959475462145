<div class="container-fluid vh-100 bg-dark pt-2 pb-2">
    <div class="container h-100">
        <ng-content select=".outside-container"></ng-content>
        <div class="row align-items-center justify-content-center h-100">
            <div class="col-sm-5">
                <div class="bg-light rounded-lg border border-dark p-5">
                    <ng-content select=".inside-container"></ng-content>
                </div>
                <ng-content select=".below-container"></ng-content>
            </div>
        </div>
    </div>
</div>