export const environment = {
  production: true,
  firebaseConfig: {
    apiKey: "AIzaSyBuREJza-VzOC_qqLRPD7lN5ujE7oZd7Z8",
    authDomain: "harley-skills.firebaseapp.com",
    projectId: "harley-skills",
    storageBucket: "harley-skills.appspot.com",
    messagingSenderId: "70701713934",
    appId: "1:70701713934:web:3a2031904e2d8c0ccb0a5f",
    measurementId: "G-7PC940Q5XG"
  }
};
