import { Module } from './../models/modules';
import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})

export class FirebaseFirestoreModulesService {
  private static _modules: Subject<Module[]> = new Subject<Module[]>();
  public loading: boolean = false;

  constructor(private firestore: AngularFirestore) { }

  public get modules(): Observable<Module[]> {
    return FirebaseFirestoreModulesService._modules.asObservable();
  }

  public loadAll(): void{
    this.loading = true;
    this.firestore.collection('modules').get().toPromise().then(
      (success: firebase.default.firestore.QuerySnapshot<Module>) => {
        let data: Module[] = new Array<Module>();

        success.forEach(module => {
          data.push(module.data());
        })

        FirebaseFirestoreModulesService._modules.next(data);
      }
    );
    this.loading = false;
  }
}
