import { ImageCropperModule } from 'ngx-image-cropper';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { ScreenCenteredContainerComponent } from './components/screen-centered-container/screen-centered-container.component';
import { WrongRouteComponent } from './components/wrong-route/wrong-route.component';
import { UserContainerComponent } from './components/user-container/user-container.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { NgxLoadingModule } from 'ngx-loading';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ConfirmModalComponent } from './modals/confirm-modal/confirm-modal.component';

@NgModule({
  declarations: [
    AlertModalComponent,
    ScreenCenteredContainerComponent,
    WrongRouteComponent,
    UserContainerComponent,
    NavigationComponent,
    ImageCropperComponent,
    ConfirmModalComponent
  ],
  exports: [
    ScreenCenteredContainerComponent,
    UserContainerComponent,
    NavigationComponent,
    ImageCropperComponent
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    NgbModule,
    NgxLoadingModule,
  ]
})

export class SharedModule { }
