import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-screen-centered-container',
  templateUrl: './screen-centered-container.component.html',
  styleUrls: ['./screen-centered-container.component.scss']
})
export class ScreenCenteredContainerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
