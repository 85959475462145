import { UserService } from './../../shared-module/services/user.service';
import { User } from './../models/user';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class FirebaseFirestoreAuthService {
  constructor(
    private firestore: AngularFirestore,
    private userService: UserService
    ) { }

  public createUser(user: User): Promise<void> {
    return this.firestore.collection('users').doc(user.id).set(user);
  }

  public loadUser(id: string): Promise<any>{
    return this.firestore.collection('users').doc(id).get()
      .toPromise()
      .then(
        (success: any) => {return {success: true, content: this.userService.user = success.data()}},
        (failure: any) => {return {success: false, content: failure}},
      );
  }
}
