import { ConfirmModalComponent } from './../modals/confirm-modal/confirm-modal.component';
import { ClientFormModalComponent } from './../../clients-module/modals/client-form-modal/client-form-modal.component';
import { Injectable, Component } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';
import { AlertTypes } from '../utils/alert-types.enum';

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  
  constructor(private modalService: NgbModal) { }

  public showModalWithExpectedResult(component: any, object?: any): Subject<any> {
    const modalRef: NgbModalRef = this.modalService.open(component, { size: 'md' });
    modalRef.componentInstance.result = new Subject<any>();
    modalRef.componentInstance.client = object;
    return modalRef.componentInstance.result;
  }

  public showDangerAlert(message: string): void {
    this.showAlert(message, AlertTypes.DANGER);
  }

  public showSuccessAlert(message: string): void {
    this.showAlert(message, AlertTypes.SUCCESS, 3000);
  }

  private showAlert(message: string, type: AlertTypes, dismissTimeout?: number): void {
    const modalRef: NgbModalRef = this.modalService.open(AlertModalComponent);
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.message = message;
    if (dismissTimeout) {
      setTimeout(() => modalRef.close(), dismissTimeout);
    }
  }

  public showConfirm({ confirmText = 'Sim', cancelText = 'Não', title, message }: { title: string, message: string, confirmText?: string, cancelText?: string }): Subject<boolean> {
    const modalRef: NgbModalRef = this.modalService.open(ConfirmModalComponent, { size: 'md', centered: true, backdrop: 'static' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.confirmResult = new Subject<boolean>();
    modalRef.componentInstance.confirmText = confirmText;
    modalRef.componentInstance.cancelText = cancelText;
    return modalRef.componentInstance.confirmResult;
  }
}
