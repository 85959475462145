import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  public imageChangedEvent: any;
  public croppedImage: string;
  @Output() crop: EventEmitter<string> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {  }

  public imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.crop.emit(this.croppedImage);
  }
}
